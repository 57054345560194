@use "../../config/variables.scss" as vars;

.RetailExpress {
  // background-color: #f6f4f0;
  overflow: hidden;
}

.RetailExpress-box2 {
  height: 70vh;
  overflow: scroll;
  // background-color: #f6f4f0;
}

.RetailExpress-box3 {
  height: 100%;
  background-color: #fff;
  border-left: 1px solid rgb(222, 222, 222);
  display: flex;
  flex-direction: column;
}

.RetailExpress-ProductItem {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  overflow: hidden;
}

.RetailExpress-ProductLisr {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  border: 1px solid green;
  overflow: hidden;
}

.RetailExpress-ProductItemImg {
  width: 100%;
  height: 100px;
  object-fit: contain;
  background-color: rgb(246, 246, 246);
}

.RetailExpress-ProductItemBox {
  padding: 10px;
  border-top: 1px solid rgb(222, 222, 222);
}

.RetailExpress-txt1 {
  font-family: vars.$SemiBold;
  font-size: 20px;
}
.RetailExpress-header2 {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.RetailExpress-box4 {
  height: 84%;
  padding: 15px;
  overflow: scroll;
}

.RetailExpress-ListItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(222, 222, 222);
  padding-bottom: 10px;
  margin-top: 10px;

  &:last-child {
    border-bottom: 0px;
  }
}

.RetailExpress-ListItemtxt {
  margin-right: 10px;
  font-family: vars.$SemiBold;
  font-size: 14px;
}

.RetailExpress-ListItemtxt1 {
  font-family: vars.$Medium;
  font-size: 15px;
}

.RetailExpress-ListItemtxt2 {
  font-size: 13px;
  color: gray;
}

.RetailExpress-table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

.RetailExpress-txt5 {
  font-family: vars.$Regular;
  color: grey;
  font-size: 14px;
}

.RetailExpress-txt6 {
  text-align: right;
  font-family: vars.$Medium;
}

.RetailExpress-ProductItemtxt1 {
  font-family: vars.$Regular;
  font-size: 14px;
}

.RetailExpress-ProductItemtxt2 {
  font-family: vars.$SemiBold;
  font-size: 14px;
}

.RetailExpress-ProductItemtxt3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: red;
}

.RetailExpress-box7 {
  border-top: 1px solid rgb(222, 222, 222);
  padding: 14px;
}

.holder_container {
  border: solid #d3d3d3 1px;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.holder_text {
  font-size: 14px;
  font-family: vars.$SemiBold;
  display: flex;
  justify-content: space-between;
}

.holder_text2 {
  font-size: 13px;
  font-family: vars.$Regular;
}

.profile-img {
  width: 45%;
  border-radius: 100%;
  cursor: pointer;
  background-color: #fff;
  padding: 5px;
  border: 1px solid rgb(217, 217, 217);
}

.profile-picture {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .mobileFixedButtonsContainer {
    position: fixed;
    bottom: 0;
    width: 93%;
  }
}
