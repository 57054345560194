@use "../../config/variables.scss" as vars;

.reportCard {
  border: 1px solid #eee;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
  .iconDiv {
    width: 50px;
    height: 50px;
    background-color: rgb(246, 239, 231);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .rCardbox {
    display: flex;
    align-items: center;
    font-family: vars.$Regular;
    font-size: 17px;
    justify-content: space-between;
  }

  .titleDiv {
    font-family: vars.$SemiBold;
    font-size: 17px;
  }

  .countTxt {
    font-family: vars.$Bold;
    font-size: 18px;
  }
}

.reportCard2 {
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 10px;
}

.reportListBox {
  height: 60vh;
  overflow-y: auto;
}

.reportList {
  overflow-y: auto;
  height: 120px;
  width: 100%;
  border: solid #eeeeee 1px;
  padding: 7px;
  display: flex;
  justify-content: space-between;

  .listBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .lestText {
    font-family: vars.$Bold;
    font-size: 17px;
    color: vars.$Primary;
  }

  .lestText2 {
    font-family: vars.$Bold;
    font-size: 10px;
    color: vars.$gray;
  }

  .lestText3 {
    font-family: vars.$Bold;
    font-size: 14px;
    color: vars.$Secondary;
  }

  .lestText4 {
    font-family: vars.$Bold;
    font-size: 12px;
  }

  .listBox2 {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .listDate {
    font-family: vars.$Regular;
    font-size: 15px;
    color: vars.$gray;
  }
}

@media (max-width: 568px) {
  .reportList {
    width: 545;
  }
}

.total-div {
  text-align: end;
  font-family: vars.$SemiBold;
}