@use "../../config/variables.scss" as vars;

.denominationForm {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .denominationInput {
    width: 82%;
  }
}

.denomination {
  table {
    font-family: vars.$Regular;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .denomination_tr {
    background-color: #dddddd;
  }
}

.short-balance {
  display: flex;
  justify-content: end;
  color: #ff0000;
  font-size: 16px;
  font-family: vars.$SemiBold;
}

.top-card {
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  padding: 5px;
  height: 100%;
  .text {
    font-family: vars.$ExtraBold;
    font-size: 18px;
  }

  .text2 {
    font-family: vars.$Medium;
    font-size: 13px;
  }
}
