@use "../../config/variables.scss" as vars;

.RetailExpress-Header {
  background-color: #fff;
  border-bottom: 1px solid rgb(222, 222, 222);
  padding: 21px 10px 15px 10px;
}

.Navigation-HeaderTimeBox {
  font-family: vars.$Medium;
  font-size: 14px;
  border: 1px solid rgb(212, 212, 212);
  padding: 4px 12px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
