@use "../../config/variables.scss" as vars;

.product {
  // background-color: #f6f4f0;
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

// .product-box1 {
//   overflow: scroll;
//   height: 70vh;
// }

.ProductItem {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  overflow: hidden;
}

.ProductItemBox {
  background-color: #fff;
  height: 100%;
  border: 1px solid rgb(236, 236, 236);
  overflow: hidden;
  padding: 10px;
}

.ProductItemImg {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.ProductItemImg2 {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.ProductItemBox {
  padding: 10px;
  border-top: 1px solid rgb(222, 222, 222);
}

.ProductItemtxt1 {
  font-family: vars.$Regular;
  font-size: 14px;
}

.ProductItemtxt2 {
  font-family: vars.$SemiBold;
  font-size: 14px;
}

.ProductItemtxt3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.productItemBtn {
  color: vars.$Secondary;
  cursor: pointer;
  font-size: 30px;

  :active {
    color: #287a4f;
    font-size: 31px;
  }
}

.image-box {
  height: 190px;
  width: 100%;
  border: solid #d3d3d3 1px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: self-start;

  :where(.css-i8ug7r).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  :where(.css-i8ug7r).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 100%;
    height: 190px;
    margin-inline-end: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
  }

  :where(
      .css-dev-only-do-not-override-i8ug7r
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  :where(
      .css-dev-only-do-not-override-i8ug7r
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    height: 190px;
    width: 100%;
    margin-inline-end: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;

    img {
      width: 170px;
      height: 170px;
    }
  }
}

.edit-icone {
  display: flex;
  justify-content: end;
  padding: 10px 10px 0px 00px;
}

.edit-icone2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
