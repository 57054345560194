@use "../../config/variables.scss" as vars;

.tableHeadingDenominations {
  font-family: vars.$ExtraBold;
  font-size: 20px;
  margin-bottom: 10px;
}

.view-counter {
  .image {
    height: 300px;
  }
  .list-card {
    display: flex;
    border-bottom: 1px solid vars.$gray;
    padding-bottom: 14px;
    margin-top: 50px;
  }
  .card-icone {
    background-color: antiquewhite;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-right: 10px;
  }
  .report-card {
    height: 75px;
    border: 1px solid vars.$gray;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .report-text {
    font-family: vars.$ExtraBold;
    color: vars.$Secondary;
    font-size: 18px;
  }
  .report-text2 {
    font-family: vars.$Bold;
    color: vars.$Primary;
    font-size: 16px;
  }
  .list-text {
    font-family: vars.$ExtraBold;
    font-size: 18px;
  }
}
