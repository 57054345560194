@use "../../config/variables.scss" as vars;
.website-LoginBox1 {
  height: 100vh;
  background-image: linear-gradient(
    170deg,
    
    #f0fef7
  );
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}
.website-LoginBox2 {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 35px 40px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 25px;
  }
}
.website-Logintxt1 {
  font-family: vars.$Medium;
  text-align: center;
  font-size: 30px;
}
.website-Logintxt2 {
  font-family: vars.$Regular;
  text-align: center;
  font-size: 12px;
  background-color: #fff6e8;
  padding: 5px;
  border-radius: 7px;
  border: 1px dashed rgb(177, 177, 177);
}
.website-Logintxt3 {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  font-family: vars.$Medium;
  font-size: 14px;
}
.website-Logintxt4 {
  text-align: center;
  font-family: vars.$Medium;
  font-size: 14px;
}
.website-LoginLine {
  width: 100%;
  background-color: rgb(221, 221, 221);
  height: 1px;
}
.website-Logintxt5 {
  font-family: vars.$Regular;
  text-align: center;
  font-size: 12px;
  color: gray;
  margin-top: -10px;
  background-color: #fff;
  width: 10%;
}
.website-Logintxt6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.website-LoginBtn1 {
  align-items: center;
  text-align: center;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 7px;
  padding: 10px;
  font-family: vars.$Medium;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}
.website-LoginBack {
  margin: 20px 20px 0px 20px;
  font-family: vars.$Medium;
  display: flex;
  align-items: center;
  font-size: 30px;
}

// updateemail page ------------>
.updateEmail-Box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.updateEmail-Box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: vars.$Bold;
  font-size: 30px;
}
