@use "../../config/variables.scss" as vars;

.drawerScreen-logo {
  width: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 10%;
  img {
    height: 100px;
    object-fit: contain;
  }
}

.drawerScreen-logo {
  width: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 68px;
  img {
    height: 100px;
    object-fit: contain;
  }
}

.draweText {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: vars.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 5px;
  &:hover {
    background-color: #b4ffdb;
    cursor: pointer;
  }
}

.draweText2 {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: vars.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
}

.draweClockIn {
  height: 40px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: vars.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
}

.sidebarClockIn {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: vars.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
}

.draweStaff-Box1 {
  padding: 10px;
  background-color: #87f6c1b0;
  width: 100%;
  border-radius: 7px;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: vars.$Regular;
  margin-top: 10px;
  cursor: pointer;
  .drawe-image {
    width: 40px;
    height: 40px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  div {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  .DrawerBName {
    font-family: vars.$ExtraBold;
    font-size: 12px;
  }
}

.sidebar-Box1 {
  padding: 10px;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: vars.$Regular;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-left: 0px;
  border-right: 0px;
  .drawe-image {
    width: 40px;
    height: 40px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  div {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  .DrawerBName {
    font-family: vars.$ExtraBold;
    font-size: 12px;
  }
}

.drawe-image2 {
  margin-top: 15px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 100%;
    border-radius: 100%;
  }
}

.drawerContent {
  // height: 60%;
  overflow: scroll;
}

.logout-box {
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 10px;
  &:hover {
    background-color: #ff191957;
  }
}
.SiderBar-txt1 {
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: #18a762;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SiderBar-txt2 {
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: #18a762;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.collapse-btn {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 100%;
    background-color: #87f6c1b0;
  }
}

.drawer-container {
  padding: 20px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
}

.popover-head {
  font-family: vars.$ExtraBold;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.popover-button {
  font-family: vars.$Regular;
  font-size: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #18a762;
    border-radius: 6px;
    color: #fff;
  }
}
