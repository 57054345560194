@use "../../config/variables.scss" as vars;
.viewpopover-Box2 {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  &:hover {
    border-left: 1px solid red;
    background-color: #ededed;
  }
}
.viewpopover-Box1 {
  width: 100px;
}
.viewpopover-Txt1 {
  font-family: vars.$Medium;
  font-size: 12px;
  color: #1a1a1a;
  padding-left: 8px;
}

.table-actionBoxItem {
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: space-around;
  border-radius: 3px;
  align-items: center;
  &:hover {
    background-color: #cfc8c8;
    color: green;
  }
}
