@use "../../config/variables.scss" as vars;

.company-logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  // background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #efbf88;
  cursor: pointer;
}

.company-name-text {
  font-family: vars.$Bold;
  font-size: 18px;
}

.company-primary-Button {
  border-radius: 10px !important;
  font-family: vars.$Regular;
  border: 2px solid #efbf88;
  background-color: #f6efe7;
}

.company-card-icon-text {
  font-family: vars.$Regular;
  font-size: 14px;
  padding-left: 5px;
}

.company-logo-profile {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: white;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #efbf88;
  cursor: pointer;
}

.company-inside-box {
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}

.main-box-container {
  width: 250px !important;
  height: 300px !important;
  padding-top: 10px;
  background-color: #f6efe7;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}
.sala-box {
  overflow-y: auto;
}
.sales-table {
  table {
    font-family: vars.$Medium;
    border-collapse: collapse;
    width: 100%;
  }

  tbody {
    tr {
      font-family: vars.$Medium;
      font-size: 12px;
    }

    tr:hover {
      background-color: #fff;
      box-shadow: -1px 2px 10px -2px rgba(0, 0, 0, 0.2);
    }
  }

  thead {
    th {
      border-radius: 0px !important;
      font-family: vars.$SemiBold;
      background-color: #eaeaea !important;
      font-size: 14px;
    }
  }
}

.table-font {
  font-family: vars.$SemiBold;
  font-size: 15px;
  margin-left: 15px;
}