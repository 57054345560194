@use "../../config/variables.scss" as vars;
.contact-txt1 {
  font-family: vars.$Bold;
  font-size: 32px;
  color: vars.$Secondary;
}
.contact-txt2 {
  font-family: vars.$Medium;
  font-size: 14px;
  color: grey;
}
.contact-Box2 {
  margin-top: 15px;
}
.contact-Box3 {
  display: flex;
  align-items: center;
}
.contactForm-Btn {
  font-family: vars.$SemiBold;
  font-size: 14px;
}
.formLabel {
  font-family: vars.$Medium;
  font-size: 12px;
}
