@font-face {
  font-family: "Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
.img {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}
@media (max-width: 568px) {
  .img {
    height: 100%;
  }
}
.main {
  height: 80vh;
}

::-webkit-scrollbar {
  display: none;
}
