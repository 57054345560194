@use "../../config/variables.scss" as vars;
////////////////////////////////////////////------BLOCK-1----///////////////////////////////////////////////////////
.Block1-Box1 {
  width: 100%;
  height: 87vh;
  display: flex;
  align-items: end;
}
.block1-Box6 {
  height: 100vh;
  width: 100%;
  @media (max-width: 568px) {
    height: 100%;
  }
}
.Block1-Image1 {
  height: 350px;
}
.Block1-Box2 {
  width: 20px;
}
.Block1-Img2 {
  max-height: 350px;
}
.Block1-Box3 {
  display: flex;
}
.Block1-txt1 {
  font-family: vars.$Bold;
  font-size: 38px;
  @media (max-width: 568px) {
    text-align: center;
    font-family: vars.$ExtraBold;
  }
}
.Block1-txt2 {
  font-family: vars.$Medium;
  font-size: 16px;
  @media (max-width: 568px) {
    text-align: center;
  }
}
.Block1-Box4 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 30px;
  @media (max-width: 568px) {
    justify-content: center;
    gap: 20px;
  }
}
.Block1-Box5 {
  max-width: 200px;
  @media (max-width: 568px) {
    max-width: 250px;
  }
}
.Block1-Box7 {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 568px) {
    padding: 20px;
  }
}
.Block-NewTxt {
  font-family: vars.$Medium;
  font-size: 16px;
}
.Block2-Box2 {
  @media (max-width: 568px) {
    padding: 20px;
  }
}
.Block1-AppImg {
  max-width: 200px;
}
.Block1-AppImg2 {
  max-width: 184px;
}
////////////////////////////////////////////-----BLOCK-2-----////////////////////////////////////////////////////////
.Block2-Img {
  width: 100%;
  height: 100%;
}
.Block2-Img2 {
  width: 100%;
  height: 100px;
}
.Block2-Box1 {
  display: flex;
  justify-content: flex-end;
}
.block2-txt1 {
  font-family: vars.$Bold;
  font-size: 24px;
  padding-bottom: 10px;
  @media (max-width: 568px) {
    text-align: justify;
    font-size: 18px;
  }
}
//////////////////////////////////////////////////////-----BLOCK-3-----/////////////////////////////////////////////
.Block3-Img {
  width: 100%;
  object-fit: contain;
}
.Block-Img1 {
  width: 100%;
  height: 150px;
}
.Block-Img3 {
  width: 100%;
  height: 150px;
}
////////////////////////////////////////////////////////------RetailXpressVideo------////////////////////////////////////////////////////////////
.RetailXpressVideo-Box1 {
  position: relative;
  height: 90vh;
  padding: 20px;
  @media (max-width: 568px) {
    height: 40vh;
  }
}
.RetailXpressVideo-Box2 {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.RetailXpressVideo-Box3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
///////////////////////////////////////////////////--------BLOCK_4------------/////////////////////////////////////
.Block4-Img1 {
  width: 100%;
  height: 222px;
  object-fit: contain;
}
.Block4-Img2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

//------------video-------------------//
.video-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  height: 400px;
}
.video-text1 {
  font-family: vars.$Medium;
  font-size: 20px;
  // font-weight: 500;
  color: #34b628;
}
.video-text2 {
  display: flex;
  font-family: vars.$Medium;
}
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #18a762;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #18a762;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}
.video-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
